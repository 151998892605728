<template>
    <div class="container">
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="label">
                    医院名称
                </div>
                <div class="value">{{hospitalData.hospitalname}}</div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="label">
                    推广二维码
                </div>
                <el-image
                        v-if="Boolean(hospitalData.qrcodeurl)"
                        style="width: 150px; height: 150px"
                        :src="hospitalData.qrcodeurl"
                        fit="fill"></el-image>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "ViewRoleDetails",
        props: {
            hospitalData: Object,
        },
        data(){
            return {

            }
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped>
    .container{

    }
    .label{
        line-height: 30px;
        color: #303133;
    }
    .value{
        line-height: 30px;
        color: #909399;
    }
</style>